<template>
    <div class="contactBox">
        <div class="contactNav">
            <img src="../assets/img/Frame4368.png" alt="">
        </div>
        <div class="touch">
            <div class="touchBox">
                <div class="touchLeft">
                    <i class="touchTit">联系我们</i>
                    <div class="emile1">
                       <span class="emileTitle1">公司邮箱</span>
                       <span class="emileMain1">service@nituowola.com</span>
                    </div>
                    <div class="site1">
                      <span class="siteTitle1">公司地址</span>
                      <span class="siteMain1">宁波市鄞州区国贸大厦7楼</span>
                      <el-row class="warp">
                         <el-col :span="24" class="warp-main">
                           <div class="map">
                             <img src="../assets/img/map.png" alt="">
                           </div>
                         </el-col>
                      </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

  data () {
    return {
      info: ''
    }
  },
  
    }
</script>

<style lang="less" scoped>
.contactNav{
    height: 95px;
}
.touch{
    height: 600px;
    background-color: #fff;
    padding-top: 60px;
    padding-left: 72px;
    box-sizing: border-box;
    position: relative;
}
.touchBox{
    width: 1296px;
    height: 400px;
}
.touchTit{
  font-style: normal;
    font-size: 24px;
    font-weight: bold;
}
.emile1{
    margin-top: 25px;
  }
.emileTitle1,.emileMain1,.siteTitle1,.siteMain1{
    font-size: 16px;
    color: #000;
  }
.emileMain1{
      color: rgba(44, 104, 255, 0.85);
  }
.emileTitle1,.siteTitle1{
    margin-right: 10px;
  }
.site1{
    margin-top: 10px;
  }
.siteMain1{
    width: 295px;
    display: inline-block;
    vertical-align: top;
  }
.mapBox{
    position: absolute;
    top: 65px;
    right: 0;
    // overflow-y: scroll;
}

#dituContent::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.iframeClass{
    width: 864px;
    height: 400px;
}
.map{
  position: absolute;
  right: 0;
  top: -90px;
}
#allmap {
    width: 864px;
    height: 400px;
    background-color: lightgreen;
  }
</style>